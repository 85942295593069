var render = function () {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c("AConfirmation", {
        attrs: { isShow: _vm.isPending },
        on: { cancel: _vm.cancelSubmit, confirm: _vm.submit },
      }),
      _c("ASuccessFour", { attrs: { api: this.api } }),
      _c("v-skeleton-loader"),
      _c(
        "v-row",
        [
          _c(
            "v-col",
            { staticClass: "mx-auto", attrs: { cols: "12", sm: "7" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "my-4",
                  attrs: { color: "primary" },
                  on: {
                    click: function () {
                      this$1.$router.go(-1)
                    },
                  },
                },
                [_c("v-icon", [_vm._v(" mdi-arrow-left-bold ")])],
                1
              ),
              _c(
                "v-card",
                { staticClass: "pa-4" },
                [
                  _c(
                    "v-toolbar",
                    { staticClass: "secondary", attrs: { height: "auto" } },
                    [
                      _c(
                        "v-toolbar-title",
                        { staticClass: "white--text text-h4" },
                        [_vm._v(" Role edit ")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row" },
                    [
                      _c("v-text-field", {
                        staticClass: "mt-4",
                        attrs: {
                          label: "Name",
                          disabled: "",
                          filled: "",
                          dense: "",
                          outlined: "",
                        },
                        model: {
                          value: _vm.name,
                          callback: function ($$v) {
                            _vm.name = $$v
                          },
                          expression: "name",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row" },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          label: "Roles..",
                          items: _vm.roles,
                          "item-text": "name",
                          "return-object": "",
                          "small-chips": "",
                          outlined: "",
                          dense: "",
                          multiple: "",
                          "deletable-chips": "",
                        },
                        model: {
                          value: _vm.rolesSelected,
                          callback: function ($$v) {
                            _vm.rolesSelected = $$v
                          },
                          expression: "rolesSelected",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-end" },
                    [
                      _c("v-btn", { attrs: { color: "primary", plain: "" } }, [
                        _vm._v(" Cancel "),
                      ]),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "primary",
                            loading: _vm.api.isLoading,
                          },
                          on: { click: _vm.validateInput },
                        },
                        [_vm._v(" Confirm ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }